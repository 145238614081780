


export const colors = {
    primary: import.meta.env.VITE_MAIN_COLOR || "#408f7a",
    // primary: "#408f7a",
    "primary-dark": "#BB0056",
    secondary: "#282828",
    "on-secondary": "#fff",
    success: "#137100",
    info: "#16B1FF",
    warning: "#FFB400",
    error: "#FF5B64",
    "shadow": "#dcdcdc",
    "on-primary": "#FFFFFF",
    "on-success": "#FFFFFF",
    "on-warning": "#FFFFFF",
    'background': "#FFFFFF",
    "on-background": "#3A3541",
    "land-background": "#F5F5FE",
    "on-surface": "#3A3541",
    "grey-50": "#FAFAFA",
    "grey-100": "#F5F5F5",
    "grey-200": "#EEEEEE",
    "grey-300": "#E0E0E0",
    "grey-400": "#BDBDBD",
    "grey-500": "#9E9E9E",
    "grey-600": "#757575",
    "grey-700": "#616161",
    "grey-800": "#424242",
    "grey-900": "#212121",
    "no-answer": "#4945FF",
    "no-answer-base": "#DFDEFF",
    "follow-up": "#FF7A00",
    "follow-up-base": "#FFE7D1",
    "interested": "#1E6D08",
    "interested-base": "#D2E2CE",
    "not-interested-base": "#FFEFEF",
    "not-interested": "#FF5B64",
    "table-background" : "#F7F7F8",
    'completed': '#BDFECF',
    "active-title": "#363FAD",
    "border": "#a3a3a3",
    "input-background": "#f6f6f6"
};

export const variables = {
    "border-color": "#3A3541",
    "medium-emphasis-opacity": 0.68,
    // ... باقي المتغيرات ...
};
